exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-cust-sat-ex-meas-cant-see-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/cust-sat-ex-meas-cant-see/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-cust-sat-ex-meas-cant-see-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-dmd-in-detail-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/dmd-in-detail/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-dmd-in-detail-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-fcst-wo-hist-data-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/fcst-wo-hist-data/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-fcst-wo-hist-data-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-ht-cnct-pelas-to-rev-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/ht-cnct-pelas-to-rev/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-ht-cnct-pelas-to-rev-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-ht-est-dmd-curve-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/ht-est-dmd-curve/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-ht-est-dmd-curve-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-pvt-tbl-missing-insight-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/pvt-tbl-missing-insight/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-pvt-tbl-missing-insight-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-rev-mgmt-in-3-steps-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/rev-mgmt-in-3-steps/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-rev-mgmt-in-3-steps-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-two-wys-tst-wht-cust-want-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.js?__contentFilePath=/codebuild/output/src564359387/src/src/frontend/src/data/blog/two-wys-tst-wht-cust-want/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-js-content-file-path-src-data-blog-two-wys-tst-wht-cust-want-index-mdx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("./../../../src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-of-use-jsx": () => import("./../../../src/pages/legal/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-legal-terms-of-use-jsx" */),
  "component---src-pages-prod-index-jsx": () => import("./../../../src/pages/prod/index.jsx" /* webpackChunkName: "component---src-pages-prod-index-jsx" */)
}

